::-webkit-scrollbar {
	display: none;
  }

  .ask-chatgpt {
    background-color: yellow;
    font-weight: bold;
  }

.ql-container {
  font-size: 20px !important
}